@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



body {
  font-family: 'Poppins', sans-serif;
  background-color: black;
}




.scene {
  width: 200px;
  height: 200px;
  perspective: 600px;
  perspective-origin: 50% 100px;
}

.polyhedron {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(0deg) rotateY(0deg);
  animation: rotate 5s infinite linear;
}

.face {
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(0,0,0,0.5);
}

.face1 {
  transform: rotateY(0deg) skewY(30deg) translateX(50px) translateY(-50px) translateZ(50px);
}

.face2 {
  transform: rotateY(120deg) skewY(-30deg) translateX(50px) translateY(-50px) translateZ(50px);
}

.face3 {
  transform: rotateX(-120deg) skewX(-30deg) translateX(50px) translateY(-50px) translateZ(50px) rotateZ(60deg);
}

.face4 {
  transform: rotateY(180deg) skewY(30deg) translateX(50px) translateY(-50px) translateZ(50px);
}

.face5 {
  transform: rotateY(60deg) skewY(-30deg) translateX(50px) translateY(-50px) translateZ(50px);
}

@keyframes rotate {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

.highlight-container {
  position: relative;
  width: 100%;
}

.scrolling-text {
  width: 100%; /* Adjust based on your requirement */
  overflow: hidden;
  white-space: nowrap;
}

.scrolling-text h1 {
  display: inline-block;
  padding-left: 100%; /* Start the animation offscreen */
  animation: scrollText 20s linear infinite;
}

@keyframes scrollText {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideInFromLeft 0.5s ease-out forwards;
}



